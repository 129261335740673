.form-title{
    text-align: left;
    font-weight: 700;
}

.form-input{
    border: none !important;
    border-radius: 0 !important;    
    border-bottom: 2px solid !important;
    
}

#register-btn{
    background:  rgb(220, 220, 220);
    width: 100%;
    border: 0px;
    border-radius: 4px;
    padding: 10px 25px;
    margin: 10px 0px;
}

form .row {
    margin-left: 0;
    margin-right: 0;
}

.form-control:focus{
    box-shadow: none !important;
    border-bottom: #007bff 2px solid !important;
}