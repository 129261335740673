@import url(https://fonts.googleapis.com/css?family=Catamaran:400,700&display=swap);
body {
    font-family: 'Catamaran', sans-serif !important;
    
}

.App {
  text-align: center;
}

.navbar{
    position: static;
    width: 100%;
    padding: 10px 30px !important;
}

nav{
    z-index: 1000;
}

.navbar-scroll{
    background: white;
}


.inactive{
    color: white;
}

.inactive:hover{
    color:rgb(212, 212, 212);
}

.active{
    color: #007bff !important;
}




/* ******** Responsive Styles *********/
@media (max-width: 767px){
    .nav{
         display: block !important;
     }
 
     .navbar h5{
         font-size: 20px;
         text-align: left;
     }

     .navbar{
         background: black !important;
     }
 
     .navbar-collapse{
         background: black;
         position: relative !important;
         z-index: 10000 !important;
     }
 
 
 }
 
 
 @media (max-width: 575.98px){
     .navbar h5{
          font-size: 15px;
      }
  
  }
 

#home-container{
    background:
    -webkit-gradient(
        linear,
        left top, left bottom,
        from(rgba(0, 0, 0, 0.267)),
        to(rgba(0, 0, 0, 0.267))  
    ),
    
    url(/static/media/lake.c561f9ae.jpg);
    background:
    -webkit-linear-gradient(
        rgba(0, 0, 0, 0.267),
        rgba(0, 0, 0, 0.267)  
    ),
    
    url(/static/media/lake.c561f9ae.jpg);
    background:
    linear-gradient(
        rgba(0, 0, 0, 0.267),
        rgba(0, 0, 0, 0.267)  
    ),
    
    url(/static/media/lake.c561f9ae.jpg);
    background-size: cover;
    height: 100vh;
    color: white;  
    padding-top: 300px;
}

.home-title{
    font-weight: 700 !important;
    font-size: 50px;    
}

.home-info{
    font-size: 20px;
    margin: auto;
}


#home-container .btn{
    margin: 30px;
    font-size: 13px;
    padding: 15px 40px 15px 40px;
}



.about-container{
    margin-top: 10%;
    margin-bottom: 10%;
    
}

.icon{
    background-color: #eee;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    margin: auto;
    margin-bottom: 20px;
  
    color: #007bff;
}

.icon i{
    line-height: 2;
}

.icon:hover{
    -webkit-transition: ease-in-out .2s;
    transition: ease-in-out .2s;
    background-color: #007bff;
    color: white;
}

.form-title{
    text-align: left;
    font-weight: 700;
}

.form-input{
    border: none !important;
    border-radius: 0 !important;    
    border-bottom: 2px solid !important;
    
}

#register-btn{
    background:  rgb(220, 220, 220);
    width: 100%;
    border: 0px;
    border-radius: 4px;
    padding: 10px 25px;
    margin: 10px 0px;
}

form .row {
    margin-left: 0;
    margin-right: 0;
}

.form-control:focus{
    box-shadow: none !important;
    border-bottom: #007bff 2px solid !important;
}
.side-image{
    background: url(/static/media/bg-02.4f699f15.jpg);
    background-size: cover;
    font-size: 45px;
    color: white;
    padding: 30% 0 20% 0;
    height: 100%;
}

.register-container > .row{
    height: 90vh;
   
}
