.side-image{
    background: url('../assets/images/bg-02.jpg');
    background-size: cover;
    font-size: 45px;
    color: white;
    padding: 30% 0 20% 0;
    height: 100%;
}

.register-container > .row{
    height: 90vh;
   
}