
#home-container{
    background:
    linear-gradient(
        rgba(0, 0, 0, 0.267),
        rgba(0, 0, 0, 0.267)  
    ),
    
    url('../assets/images/lake.jpg');
    background-size: cover;
    height: 100vh;
    color: white;  
    padding-top: 300px;
}

.home-title{
    font-weight: 700 !important;
    font-size: 50px;    
}

.home-info{
    font-size: 20px;
    margin: auto;
}


#home-container .btn{
    margin: 30px;
    font-size: 13px;
    padding: 15px 40px 15px 40px;
}


