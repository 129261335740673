.navbar{
    position: static;
    width: 100%;
    padding: 10px 30px !important;
}

nav{
    z-index: 1000;
}

.navbar-scroll{
    background: white;
}


.inactive{
    color: white;
}

.inactive:hover{
    color:rgb(212, 212, 212);
}

.active{
    color: #007bff !important;
}




/* ******** Responsive Styles *********/
@media (max-width: 767px){
    .nav{
         display: block !important;
     }
 
     .navbar h5{
         font-size: 20px;
         text-align: left;
     }

     .navbar{
         background: black !important;
     }
 
     .navbar-collapse{
         background: black;
         position: relative !important;
         z-index: 10000 !important;
     }
 
 
 }
 
 
 @media (max-width: 575.98px){
     .navbar h5{
          font-size: 15px;
      }
  
  }
 